<template>
  <div class="action-block if-else-block">
    <div class="common-action blue" @click="showModal">
      {{ block.name }}
    </div>

    <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="conditionForm">
      <form class="if-else-form" @submit.prevent="handleSubmit(save)">

        <b-modal id="if-else-modal" v-model="isShowModal" title="Condition" size="lg">
          <TextInput v-model="block.name" label="Label" />
          <TextArea
            v-if="block.options"
            v-model="block.options.invalid_responder_message"
            name="Responder"
            label="Responder for Invalid SMS"
            tooltip="We will send out this message and wait until timeout when contact reply doesn't match to any cases (except None)."
          />
          <div v-for="(branch, index) in block.branches" :key="`branch-${index}`" class="branch">
            <div class="row">
              <div class="col-md-6">
                <TextInput v-model="branch.name" placeholder="Branch Name"/>
              </div>
              <div class="col-md-6 text-right">
                <button v-if="!branch.none" class="btn btn-sm btn-danger mt-2" @click="handleRemoveBranch(index)">
                  <i class="uil uil-trash-alt"></i> Delete
                </button>
              </div>
            </div>
            <div v-for="(segment, segmentIndex) in branch.segments" :key="`segment-${segmentIndex}`" class="row">
              <div class="col-md-12">
                <div v-for="(condition, conditionIndex) in segment.conditions" :key="`condition-${conditionIndex}`"
                  class="row mb-3">
                  <div class="col-md-3">
                    <select v-model="condition.condition" class="form-control">
                      <option value="SMS">Replied Message</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <select v-model="condition.operator" class="form-control">
                      <option v-for="operator in operators" :key="operator.value" :value="operator.value"
                        :selected="condition.operator === operator.value">{{ operator.label }}</option>
                    </select>
                  </div>
                  <div class="col-md-6 d-flex align-items-center justify-content-between mb-2">
                    <TextInput v-show="showStatement(condition.operator)" v-model="condition.statement" margin-bottom="0" style="flex: 1 0 auto;" />
                    <button class="btn btn-sm btn-danger ml-2"
                      @click="handleRemoveCondition(index, segmentIndex, conditionIndex)">
                      <i class="uil uil-trash-alt"></i>
                    </button>
                  </div>
                  <div v-if="conditionIndex < segment.conditions.length - 1" class="col-md-12 mt-1">
                    <span class="pl-1 font-weight-bold">{{ segment.logic === 'And' ? 'AND' : 'OR' }}</span>
                  </div>
                </div>

                <div class="col-md-12 text-right">
                  <button v-if="segment.conditions" class="btn btn-sm btn-primary mt-2" @click="addCondition(segment)">
                    <i class="uil uil-plus"></i> Add Condition
                  </button>
                </div>
              </div>

              <div v-if="segment.conditions && segment.conditions.length > 1" class="col-md-12 mt-1 mb-4">
                <button class="btn btn-sm"
                  :class="{ 'btn-primary': segment.logic === 'And', 'btn-outline-dark': segment.logic !== 'And' }"
                  @click="handleSetLogic(segment, 'And')">
                  AND
                </button>
                <button class="btn btn-sm ml-2"
                  :class="{ 'btn-primary': segment.logic === 'Or', 'btn-outline-dark': segment.logic !== 'Or' }"
                  @click="handleSetLogic(segment, 'Or')">
                  OR
                </button>
              </div>
            </div>
          </div>
          <button class="btn btn-primary" @click="addbranch">
            <i class="uil uil-plus"></i> Add branch
          </button>
          <template v-slot:modal-footer>
            <b-button variant="primary" :disabled="invalid && touched" @click="save">Save</b-button>
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </div>
</template>
  
<script>
export default {
  props: {
    block: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      selectedBranchId: null,
      selectedConditionId: null,
      operators: [
        { label: 'Is', value: 'Is' },
        { label: 'Is not', value: 'IsNot' },
        { label: 'Contains', value: 'Contains' },
        { label: 'Does not contain', value: 'DoesNotContain' },
        { label: 'Is not empty', value: 'IsNotEmpty' },
        { label: 'Is empty', value: 'IsEmpty' },
      ]
    }
  },

  computed: {
    durationOptions() {
      return new Array(60)
    }
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
    if (!this.block.options)
      this.block.options = {
        invalid_responder_message: '',
      }
  },

  methods: {
    showStatement(op) {
      return !['IsNotEmpty', 'IsEmpty'].includes(op)
    },

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    onOpenSelect(branchId, conditionId) {
      this.selectedBranchId = branchId
      this.selectedConditionId = conditionId
    },

    addStatment(content) {
      if (this.block.branches[this.selectedBranchId] && this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId]) {
        if (!this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId].statements) {
          this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId].statements = []
        }
        this.block.branches[this.selectedBranchId].conditions[this.selectedConditionId].statements.push(content)
      }
    },

    addbranch() {
      this.block.branches.push({
        name: 'Branch',
        logic: 'And',
        segments: [
          {
            logic: 'Or',
            conditions: [
              {
                condition: 'SMS',
                operator: 'Is',
                statement: null,
              }
            ]
          }
        ]
      })
    },

    addCondition(segment) {
      segment.conditions.push({
        condition: 'SMS',
        operator: 'Is',
        statement: null,
        logic: 'Or',
      })
    },

    handleSetLogic(segment, logic) {
      segment.logic = logic
    },

    handleRemoveBranch(index) {
      this.block.branches.splice(index, 1)
    },

    handleRemoveCondition(index, segmentIndex, conditionindex) {
      if (this.block.branches[index] && this.block.branches[index].segments && this.block.branches[index].segments[segmentIndex]) {
        this.block.branches[index].segments[segmentIndex].conditions.splice(conditionindex, 1)
      }
    },
  },
}
</script>
  
<style lang="scss" scoped>
.branch {
  position: relative;
  background: #ebebeb;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
</style>
