<template>
  <div class="action-block new-block">
    <div class="new-action" @click="showActionsModal">
      <i class="uil uil-plus-circle"></i>
    </div>

    <b-modal id="new-action-modal" v-model="isShowActionsModal" title="Add a New Action"  size="lg" hide-footer>
      <div class="actions-section">
        <div class="list-groups">
          <div v-for="(group, index1) in actions" :key="`group-${index1}`" class="group-item" @click="selectGroup(index1)">
            {{ group.label }} <i class="uil uil-angle-right"></i>
          </div>
        </div>
        <div class="list-actions">
          <div v-for="(action, index2) in selectedActions" :key="`action-${index2}`" class="action-item" @click="selectAction(action)">
            <div class="icon">
              <i :class="action.icon"></i>
            </div>
            <div class="desc">
              <div class="name">
                {{ action.name }}
              </div>
              <p>
                {{ action.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: [ Number, null ],
      default: null,
    },

    level: {
      type: String,
      default: '1'
    },
  },

  data() {
    return {
      isShowActionsModal: false,
      selectedGroup: null,
    }
  },
  
  computed: {
    actions() {
      return this.$store.getters['automation/actions'] || []
    },

    selectedActions() {
      return this.actions[this.selectedGroup ? this.selectedGroup : Object.keys(this.actions)[0]].actions
    }
  },

  methods: {
    showActionsModal() {
      this.isShowActionsModal = !this.isShowActionsModal
    },

    selectGroup(group) {
      this.selectedGroup = group
    },

    selectAction(action) {
      this.$emit('selected', { action: action, index: this.index, level: this.level })
      this.isShowActionsModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.new-action {
  cursor: pointer;
}
.uil {
  font-size: 22px;
  &:hover {
    color: #5f667e;
  }
}
.list-actions {
  .action-item {
    background-color: #65758A;
    color: #FFF;
  }
}
</style>

<style lang="scss">
#new-action-modal {
  .modal-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  .actions-section {
    display: flex;
  }
  .list-groups {
    width: 300px;
    .group-item {
      background: #4e5468;
      color: #FFF;
      padding: 16px 12px;
      font-weight: 700;
      border-bottom: 1px solid #353a4a;
      cursor: pointer;
      &:hover {
        background-color: #353a4a;
      }
      .uil {
        float: right;
      }
    }
  }
  .list-actions {
    flex: 1 1 auto;
    padding: 0px 15px;
    .action-item {
      display: flex;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 10px;
      .icon {
        padding: 10px;
      }
      .name {
        font-weight: 700;
        cursor: pointer;
        padding-top: 7px;
      }
      p {
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
  }
}
</style>