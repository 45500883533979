<template>
  <div>
    <template v-if="!loadingAutomation">
      <ValidationObserver
        v-slot="{ handleSubmit, invalid, touched }"
        ref="automationForm"
      >
        <form @submit.prevent="handleSubmit(saveAutomation)">
          <div class="row">
            <div class="col-sm-6">
              <TextInput v-model="automation.name" label="Name" rules="required" name="name" />
            </div>
            <div class="col-sm-2">
              <label>Active</label>
              <div class="pt-2">
                <b-form-checkbox
                  v-model="automation.active"
                  switch
                >
                </b-form-checkbox>
              </div>
            </div>
            <div class="col-sm-4 text-right">
              <b-button
                class="mt-4 mr-2"
                variant="primary"
                :disabled="loadingSave || (invalid && touched)"
                type="submit"
              >
                <b-spinner v-if="loadingSave" small />
                <span v-else><i class="uil uil-upload-alt"></i> Save</span>
              </b-button>
              <b-button
                class="mt-4"
                variant="warning"
                @click="onClickBack"
              >
                <span><i class="uil uil-arrow-left"></i> Back</span>
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="card">
        <div class="card-body">
          <div class="automations-page">
            <div class="automation-editor">
              <Editor :automation="automation"/>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="text-center">
      <b-spinner />
    </div>
  </div>
</template>

<script>
import Editor from './Components/Editor'
const merge = require('deepmerge')

export default {
  components: {
    Editor,
  },

  data() {
    return {
      automation: {
        blocks: [],
        name: null,
        start_trigger: null,
        active: true,
        options: {},
        keywords: [],
        broadcasts: [],
      },
      loadingSave: false,
      loadingAutomation: false,
      keywords: [],
      constAutomation: null,
    }
  },

  computed: {
    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  mounted() {
    this.getKeywords()
    this.constAutomation = JSON.parse(JSON.stringify(this.automation))
    
    if (this.$route.params.id) {
      this.getAutomation()
    }
  },

  methods: {
    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },

    getAutomation() {
      this.loadingAutomation = true

      this.$store.dispatch('automation/find', this.$route.params.id)
        .then((automation) => {
          this.loadingAutomation = false

          let actionsOriginal = JSON.parse(JSON.stringify(this.$store.getters['automation/actionList']))
          
          actionsOriginal.forEach((item) => {
            if (item.action === "CollectContactData") {
              this.customFields.forEach((customItem) => {
                let format = customItem.type === 2 ? 'mm/dd' : ''
                item.collectFields.push({
                  name: customItem.id,
                  label: customItem.name,
                  type: customItem.type,
                  format: format,
                  enable: false,
                  message: `Please reply with your ${customItem.name}`
                });
              });
            }
          });

          let tmpAutomation = JSON.parse(JSON.stringify(automation))
          let blocks = tmpAutomation.blocks
          blocks.forEach(block => {
            let orgBlock = actionsOriginal.find(item => item.action === block.action)
            if (orgBlock && orgBlock.collectFields)
            {
              if (!block.collectFields)
              {
                block.collectFields = []
              }
              if (block.collectFields)
              {
                let collectFields = orgBlock.collectFields

                collectFields.forEach((item1, index) => {
                  let field = block.collectFields.find(i => i.name === item1.name)
                  field = field || {}
                  collectFields[index] = merge.all([{}, 
                    item1,
                    field
                  ]);
                })

                block.collectFields = collectFields
              }
            }
            if (orgBlock && orgBlock.options)
            {
              if (!block.options)
              {
                block.options = {}
              }
              block.options = merge.all([{}, 
                orgBlock.options,
                block.options,
              ])
            }
          });

          this.automation = tmpAutomation
          this.constAutomation = JSON.parse(JSON.stringify(this.automation))
        })
        .catch(() => {
          this.$router.push({ name: 'business.automations.index' })
          this.loadingAutomation = false
        })
    },

    saveAutomation() {
      this.loadingSave = true

      this.$store.dispatch('automation/createOrUpdate', { automation: this.automation })
        .then((res) => {
          this.loadingSave = false
          this.automation = JSON.parse(JSON.stringify(res.data))
          this.constAutomation = JSON.parse(JSON.stringify(res.data))
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.automationForm.setErrors(err.response.data.errors)
          }
          this.loadingSave = false
        })
    },

    onClickBack() {
      this.$router.push({ name: 'business.automations.index' })
    },
  },

  beforeRouteLeave (to, from , next) {
    let diff = this.difference(this.automation, this.constAutomation)

    if (Object.keys(diff).length) {
      const answer = window.confirm('Are you sure you want to leave without saving?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>