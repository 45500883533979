<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{ block.name }}
  </div>

  <b-modal
    id="alert-modal" 
    v-model="isShowModal" title="Alert" size="lg">
    <TextInput v-model="block.name" label="Label" />

    <TextAreaAction v-model="block.options.content" label="Content"
      :rows="3" :show-mergefield="true" :sms-length="true" :hide-title="true" :show-redemption="false"
      :hide-expire-date="true" :hide-upload="true" :show-keyword="true" :show-contact-link="true" :hide-contact-dropdown="false"
      :hide-samcart-dropdown="false" name="message" rules="required"/>

    <div class="form-group">
      <button class="btn" :class="{ 'btn-primary': block.options.trigger === 'AllLeads', 'btn-outline-dark': block.options.trigger !== 'AllLeads' }"
        @click="selectTrigger('AllLeads')">
        All Leads <i v-b-tooltip.hover title="Send notification for all incoming leads" class="uil uil-question-circle"></i>
      </button>
      <button class="btn ml-4"  :class="{ 'btn-primary': block.options.trigger === 'FirstTime', 'btn-outline-dark': block.options.trigger !== 'FirstTime' }"
         @click="selectTrigger('FirstTime')">
        First Time <i v-b-tooltip.hover title="Send notification whenever new contact is created" class="uil uil-question-circle"></i>
      </button>
    </div>
    <h5>Notification Emails and Phone Numbers</h5>
    <ValidationObserver v-slot="{ invalid }" ref="alertForm">
      <div v-for="(user, index) in block.options.users" :key="`user-${index}`" class="row">
        <div class="col-md-5 d-flex">
          <div class="flex-fill">
            <TextInput v-model="user.email" label="Email" :rules="user.enable_email ? 'required|email' : ''" :disabled="!user.enable_email" name="email"/>
          </div>
          <div class="d-flex mt-4 pt-3 ml-2">
            <b-form-checkbox
              v-model="user.enable_email"
              switch
            >
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-md-5 d-flex">
          <div class="flex-fill">
            <PhoneInput v-model="user.phone" label="Phone" :disabled="!user.enable_phone" name="phone" :rules="user.enable_phone ? 'required|phone' : ''"/>
          </div>
          <div class="d-flex mt-4 pt-3 ml-2">
            <b-form-checkbox
              v-model="user.enable_phone"
              switch
            >
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-md-2 d-flex align-items-center ">
          <button class="btn btn-danger btn-sm mt-3" type="button" @click="deleteUser(index)">
            <i class="uil uil-trash-alt"></i> Delete
          </button>
        </div>
      </div>
      <div class="text-left mt-2">
        <button class="btn btn-primary btn-sm" type="button" :disabled="invalid" @click="newUser">
          <i class="uil uil-plus"></i> New row
        </button>
      </div>
    </ValidationObserver>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
    
  </b-modal>
</div>
</template>

<script>
import Vue from 'vue'

export default {
  components: { },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
    }
  },


  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }

    this.block.options = {
      trigger: 'AllLeads',
      ...this.block.options
    }
  },

  methods: {
    save() {
      this.$refs.alertForm.validate().then(async (isValid) => {
        if (isValid) {
          this.isShowModal = false
        } else {
          Vue.$toast.open({
            message: 'Please make sure you have correct values.',
            type: 'default',
            duration: 3000,
          })
        }
      })
      
    },

    showModal() {
      this.isShowModal = true
    },

    newUser() {
      this.block.options.users.push({ email: null, phone: null, enable_email: true, enable_phone: true, })
    },

    deleteUser(index) {
      this.block.options.users.splice(index, 1)
    },

    selectTrigger(trigger) {
      this.block.options.trigger = trigger
    },
  },
}
</script>