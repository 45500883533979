<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <b-modal id="send-email-modal" v-model="isShowModal" title="Email" size="xl">
      <TextInput v-model="block.name" label="Label" />
      
      <div v-if="showOptions" class="form-group">
        <label>Email account <span v-b-tooltip.hover class="cursor-pointer"
            title="You can leave this blank to use system default email client."><i
              class="uil uil-question-circle"></i></span></label>
        <multiselect v-model="block.options.integration_user" :options="options" :multiple="false" :allow-empty="false"
          track-by="id" placeholder="Select Account" label="name"></multiselect>
      </div>
      <div class="row">
        <div class="col-md-6">
          <TextInput v-model="block.options.name" label="From name"></TextInput>
        </div>
        <div class="col-md-6">
          <TextInput v-if="!allowReplyTracking || !block.options.reply_tracking" v-model="block.options.from" type="email" label="From email" rules="required" name="email"></TextInput>
        </div>
        <div class="col-md-12">
          <div v-if="allowReplyTracking" class="d-block w-100 mb-4">
            <b-form-checkbox v-model="block.options.reply_tracking" @change="onReplyTrackingChange">
              Reply Tracking <i v-b-tooltip.hover
              title="Track when a contact replies to your email. The Reply-To address in your message will be changed for this feature to work properly. Your From address will not be changed."
              class="uil uil-question-circle"></i>
            </b-form-checkbox>
          </div>
          <div class="form-group">
            <MergeFieldsGroup :show-keyword="true" :show-contact-link="true" :hide-title="true" @selected="appendField" />
          </div>
          <TextInput v-model="block.options.subject" label="Subject" name="subject" rules="required"></TextInput>
          <TextEditorAction v-model="block.options.message" :object="block.options" rules="required"></TextEditorAction>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
    <b-modal v-model="replyTrackingModal" title="Reply Tracking Confirmation">
      <p>The Reply-To address in your message will be changed for this feature to work properly. Your From address will not be changed.</p>
      <p>Important replies will be forwarded to your original Reply-To email address or From email address if you did not specify a Reply-To address.</p>
      <p>Do you wish to continue?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="replyTrackingModal = false">Cancel</b-button>
        <b-button variant="primary" @click="onReplyTrackingConfirm">Continue</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect, },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
      options: [],
      replyTrackingModal: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    showOptions() {
      return this.options && this.options.length > 1 
    },

    hasSendgridIntegration() {
      return this.user && this.user.business && this.user.business.has_integrations.sendgrid
    },

    allowEmailIntegration() {
      return this.$store.getters['auth/allowEmailIntegration']
    },

    allowReplyTracking() {
      return this.block.options.integration_user && this.block.options.integration_user.type === 2 || 
        !this.block.options.integration_user && this.allowEmailIntegration && this.hasSendgridIntegration
    },
  },

  watch: {
    allowReplyTracking: function(newVal, oldVal) {
      if (newVal !== oldVal && newVal === false) {
        this.block.options.reply_tracking = false
      }
    },
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }

    this.block.options = {
      method: 'Sms',
      images: [],
      ...this.block.options
    }
  },

  methods: {
    onReplyTrackingConfirm() {
      this.replyTrackingModal = false
      this.block.options.reply_tracking = true
    },

    onReplyTrackingChange() {
      if (this.block.options.reply_tracking)
        this.replyTrackingModal = true
      this.block.options.reply_tracking = false
    },

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
      this.getSelectOptions()
    },

    getSelectOptions() {
      this.$store.dispatch('integration/selectOptions')
        .then(options => {
          this.options = options
          if (options && options.length === 1)
          {
            this.block.options.integration_user = options[0]
          }
        })
        .catch(() => { })
    },
    appendField(field) {
      document.execCommand('insertText', false, field)
    },
  }
}
</script>

<style lang="scss">
  #send-email-modal .modal-dialog{
    max-width: 600px;
  }
</style>