<template>
  <div class="action-block">
    <div class="common-action blue" @click="showModal">
      <i :class="block.icon"></i> {{ blockConditionLabel }}
      {{ block.options.operator }} "{{ blockStatement }}"?
    </div>
    <ValidationObserver
      v-slot="{ handleSubmit, invalid, touched }"
      ref="ifElseForm"
    >
      <form class="if-else-form" @submit.prevent="handleSubmit(save)">
        <b-modal
          id="if-else-modal"
          v-model="isShowModal"
          title="If/Else"
          size="lg"
         
        >
          <div class="row">
            <div class="col-md-4">
              <select v-model="block.options.condition" class="form-control">
                <option value="SMS">SMS Content</option>
                <!-- <option value="Keyword">Keyword</option> -->
              </select>
            </div>
            <div class="col-md-4">
              <select v-model="block.options.operator" class="form-control">
                <option value="Is">Is</option>
                <option value="Is Not">Is Not</option>
                <!-- <option v-if="block.options.condition === 'Keyword'" value="In">In</option> -->
              </select>
            </div>
            <div class="col-md-3">
              <TextInput
                v-model="block.options.statement"
                rules="required"
                name="YesStatement"
              />
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-4"> </div>
            <div class="col-md-4">
              <TextInput
                :value="block.options.operator === 'Is' ? 'Is Not' : 'Is'"
                :disabled="true"
              />
            </div>
            <div class="col-md-3">
              <TextInput v-model="block.options.not_statement" />
            </div>
            <div class="col-md-1">
              <a href="javascript:;" class="d-block" style="margin-top: 10px"
                ><i
                  v-b-tooltip.hover
                  title="Default Content is 'No'"
                  class="uil uil-question-circle"
                ></i
              ></a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label for="responder_message">Responder for Invalid SMS</label>
            </div>
            <div class="col-md-8">
              <TextArea
                v-model="block.options.invalid_responder_message"
                name="Responder"
                rules="required"
              />
              <SMSLength :text="block.options.invalid_responder_message" />
            </div>
          </div>
          <template v-slot:modal-footer>
            <b-button
              variant="primary"
              :disabled="invalid && touched"
              @click="save"
              >Save</b-button
            >
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue'
import SMSLength from '../../../Common/SMSLength'

export default {
  components: {
    SMSLength,
  },

  props: {
    block: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
    }
  },

  computed: {
    blockConditionLabel() {
      let result = this.block.options.condition
      switch (result) {
        case 'SMS':
          result = 'SMS Content'
          break
        default:
          break
      }
      return result
    },
    blockStatement() {
      if (
        this.block.options.condition === 'Keyword' &&
        this.block.options.operator === 'In'
      ) {
        return this.block.options.statements.join(', ')
      }

      return this.block.options.statement
    },
  },

  mounted() {
    if (!this.block.options.statements) {
      this.block.options.statements = []
    }

    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    save() {
      this.$refs.ifElseForm.validate().then(async (isValid) => {
        if (isValid) {
          this.isShowModal = false
        } else {
          Vue.$toast.open({
            message: 'Please make sure you have correct values.',
            type: 'default',
            duration: 3000,
          })
        }
      })
    },

    showModal() {
      this.isShowModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
</style>