import { render, staticRenderFns } from "./NewActionBlock.vue?vue&type=template&id=486e5fb0&scoped=true&"
import script from "./NewActionBlock.vue?vue&type=script&lang=js&"
export * from "./NewActionBlock.vue?vue&type=script&lang=js&"
import style0 from "./NewActionBlock.vue?vue&type=style&index=0&id=486e5fb0&lang=scss&scoped=true&"
import style1 from "./NewActionBlock.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486e5fb0",
  null
  
)

export default component.exports