<template>
  <div class="action-block">
    <div class="common-action dark-gray" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }}
    </div>

    <b-modal id="subscribe-contact-to-list-modal" v-model="isShowModal" title="Register Contact To Goto Webinar"
      size="lg">
      <div class="row">
        <div class="col-md-12">
          <div v-if="showOptions" class="form-group">
            <label>GoTo account</label>
            <multiselect v-model="block.options.integration_user" :options="options" :multiple="false"
              :allow-empty="false" track-by="id" placeholder="Select Account" label="name" @input="onAccountChange">
            </multiselect>
          </div>
        </div>
        <div class="col-md-12">
          <label>Webinars</label>
          <div class="text-center">
            <b-spinner v-if="loadingWebinars" small></b-spinner>
            <multiselect v-else v-model="block.options.webinars" :options="webinars" :multiple="true" track-by="id"
              placeholder="Select Webinars" label="name"></multiselect>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },

  props: {
    block: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      options: [],
      webinars: [],
      loadingWebinars: false,
    }
  },

  computed: {
    showOptions() {
      return this.options && this.options.length > 1
    },
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    save() {
      if (!this.block.options.webinars || !this.block.options.webinars.length) {
        Vue.$toast.open({
          message: 'Webinar can\'t be empty',
          type: 'error',
          duration: 3000,
        })
      } else {
        this.isShowModal = false
      }
    },

    showModal() {
      this.isShowModal = true
      this.getSelectOptions()
    },

    getSelectOptions() {
      let queries = {
        key: ['goto']
      }
      this.$store.dispatch('integration/selectOptions', queries)
        .then(options => {
          this.options = options
          if (options && options.length === 1)
          {
            this.block.options.integration_user = options[0]
          }
          if (this.block.options.integration_user)
            this.getWebinars()
        })
        .catch(() => { })
    },

    getWebinars() {
      this.loadingWebinars = true
      let params = {
        'integration_id': this.block.options.integration_user ? this.block.options.integration_user.id : undefined
      }

      this.$store.dispatch('integration/getGotoWebinars', params)
        .then(webinars => {
          this.webinars = webinars
          this.loadingWebinars = false
        })
        .catch(() => {
          this.loadingWebinars = false
        })
    },

    onAccountChange() {
      this.block.options.webinars = []
      this.getWebinars()
    }
  },
}
</script>