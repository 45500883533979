<template>
<div class="action-block">
  <div class="common-action blue" @click="showModal">
    <i :class="block.icon"></i> {{ block.name }}
  </div>

  <b-modal id="subscribe-contact-to-list-modal" v-model="isShowModal" title="Subscribe Contact To List" size="lg">
    <TextInput v-model="block.name" label="Label" />
    <div class="form-group">
      <label>Keywords</label>
      <multiselect
        v-model="block.options.campaigns"
        :options="keywords"
        :multiple="true"
        track-by="id"
        placeholder="Select keywords"
        label="name"
      ></multiselect>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },

  props: {
    block: {
      type: Object,
      default: null,
    },

    blocks: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
      keywords: [],
    }
  },

  computed: {
    contactSources() {
      let sources = [{ value: 0, label: '0. Start trigger' }]
      this.blocks.forEach((b, index) => { sources.push({ value: index + 1, label: ` ${index + 1}. ${b.name}` })})

      return sources
    },
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
      this.getKeywords()
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },
  },
}
</script>