<template>
  <div class="action-block">
    <div class="common-action" @click="showModal">
      <i :class="block.icon"></i> {{ block.name }} <template v-if="block.options.timeout && block.options.action_type !== 2"> ( {{ block.options.duration }}
        {{ block.options.period }} )</template>
      <span v-if="waitContacts"> ({{ waitContacts }} in queue)</span>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="block.options.action_type === 2 && untilLabel" class="sub-label" v-html="untilLabel">
        <!--eslint-enable-->
      </div>
    </div>

    <b-modal id="wait-modal" v-model="isShowModal" title="Wait" size="xl">
      <TextInput v-model="block.name" label="Label" />

      <div v-if="isShowModal">
        <div class="row">
          <div class="col-12 mt-2  mb-1">
            <b-form-group v-slot="{ ariaDescribedby }" label="">
              <b-form-radio v-model="block.options.action_type" :aria-describedby="ariaDescribedby" name="actionType"
                :value="1"><i>Wait for a specific period of time</i></b-form-radio>
              <b-form-radio v-model="block.options.action_type" :aria-describedby="ariaDescribedby" name="actionType"
                :value="2" class="mt-4"><i>Wait until specific conditions are met</i></b-form-radio>
            </b-form-group>
          </div>
        </div>
        <div v-if="block.options.action_type === 1" class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Wait for</label>
              <select v-model="block.options.key" class="form-control">
                <option value="wait_for">Wait for</option>
                <option value="contact_reply">Contact reply</option>
              </select>
            </div>
          </div>
          <div v-if="block.options.key === 'contact_reply'" class="col-12">
            <div class="form-group">
              <label>Reply to <i v-b-tooltip.hover
                  title="Leave blank and we will listen to replies from any notification action"
                  class="uil uil-question-circle"></i></label>
              <multiselect v-model="replyTo" :options="notifications" :multiple="true" track-by="id"
                placeholder="Select Notifications" label="name"></multiselect>
            </div>
          </div>
          <div v-if="block.options.key === 'contact_reply'" class="col-12 mt-3 mb-4">
            <label>Timeout</label>
            <b-form-checkbox v-model="block.options.timeout" switch>
            </b-form-checkbox>
          </div>
          <template v-if="block.options.timeout || block.options.key === 'wait_for'">
            <div class="col-6">
              <select v-model="block.options.duration" class="form-control">
                <option v-for="(value, index) in durationOptions" :key="`options-${index}`" :value="index + 1">{{ index +
                  1 }}</option>
              </select>
            </div>
            <div class="col-6">
              <select v-model="block.options.period" class="form-control">
                <option value="seconds">seconds</option>
                <option value="minutes">minutes</option>
                <option value="hour(s)">hour(s)</option>
                <option value="day(s)">day(s)</option>
                <option value="week(s)">week(s)</option>
                <option value="month(s)">month(s)</option>
                <option value="year(s)">year(s)</option>
              </select>
            </div>
          </template>
        </div>
        <div v-else-if="block.options.action_type === 2">
          <div v-for="(segment, segmentIndex) in block.segments.data" :key="`segment-${segmentIndex}`">
            <div v-if="segmentIndex !== 0" class="d-flex justify-content-center">
              <button class="btn btn-sm"
                :class="{ 'btn-primary': block.segments.logic === 'And', 'btn-outline-dark': block.segments.logic !== 'And' }"
                @click="handleSetLogic(null, 'And')">
                AND
              </button>
              <button class="btn btn-sm ml-2"
                :class="{ 'btn-primary': block.segments.logic === 'Or', 'btn-outline-dark': block.segments.logic !== 'Or' }"
                @click="handleSetLogic(null, 'Or')">
                OR
              </button>
            </div>
            <div class="segment">
              <div class="row">
                <div v-if="block.segments.data.length > 1" class="segment-delete"
                  @click="handleRemoveSegment(segmentIndex)">
                  <svg id="IconChangeColor" width="200" height="200" viewBox="0 0 48 48" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path id="mainIconPathAttribute" d="M14 14L34 34" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round" stroke="#fff"></path>
                    <path id="mainIconPathAttribute" d="M14 34L34 14" stroke-width="4" stroke-linecap="round"
                      stroke-linejoin="round" stroke="#fff"></path>
                  </svg>
                </div>
                <div class="col-md-12 mt-2">
                  <div v-for="(condition, conditionIndex) in segment.conditions" :key="`condition-${conditionIndex}`"
                    class="row mb-1">
                    <div class="col-md-3">
                      <multiselect v-model="condition.condition" :options="conditions" placeholder="Select Condition"
                        :allow-empty="false" :show-labels="false" label="label" group-values="data" group-label="title"
                        :group-select="true" :searchable="false" @input="onChangeCondition(condition)"></multiselect>
                    </div>
                    <div class="col-md-2">
                      <multiselect v-model="condition.operator" :options="operators" placeholder="Select Operator"
                          :allow-empty="false" :show-labels="false" :searchable="false" label="label" ></multiselect>
                    </div>
                    <div class="col-md-4">
                      <multiselect v-model="condition.timezone" :options="timezones" placeholder="Select Timezone"
                        :allow-empty="false" :show-labels="false" label="label" @input="onChangeCondition(condition)">
                      </multiselect>
                    </div>
                    <div class="col-md-3 d-flex align-items-start justify-content-between">
                      <template style="flex: 1 0 auto;">
                        <multiselect v-if="condition.condition && condition.condition.value === 'CurrentYear'"
                          v-model="condition.statement" :options="yearStatements" placeholder="Select Value"
                          :allow-empty="false" :show-labels="false" label="label"></multiselect>
                        <multiselect v-else-if="condition.condition && condition.condition.value === 'CurrentMonth'"
                          v-model="condition.statement" :options="monthStatements" placeholder="Select Value"
                          :allow-empty="false" :show-labels="false" label="label"></multiselect>
                        <multiselect v-else-if="condition.condition && condition.condition.value === 'CurrentDay'"
                          v-model="condition.statement" :options="dayStatements" placeholder="Select Value"
                          :allow-empty="false" :show-labels="false" label="label"></multiselect>
                        <multiselect v-else-if="condition.condition && condition.condition.value === 'CurrentDayOfWeek'"
                          v-model="condition.statement" :options="dayOfWeekStatements" placeholder="Select Value"
                          :allow-empty="false" :show-labels="false" label="label"></multiselect>
                        <multiselect v-else v-model="condition.statement" :options="timeStatements"
                          placeholder="Select Value" :allow-empty="false" :show-labels="false" label="label">
                        </multiselect>
                      </template>
                      <button v-if="segment.conditions.length > 1" class="btn btn-sm btn-danger ml-2 mt-1"
                        @click="handleRemoveCondition(segmentIndex, conditionIndex)">
                        <i class="uil uil-trash-alt"></i>
                      </button>
                    </div>
                    <div v-if="conditionIndex < segment.conditions.length - 1" class="col-md-12 mt-1">
                      <span class="pl-1 font-weight-bold">{{ segment.logic === 'And' ? 'AND' : 'OR' }}</span>
                    </div>
                  </div>

                  <div class="col-md-12 d-flex justify-content-between align-items-center my-2">
                    <div v-if="segment.conditions && segment.conditions.length > 1">
                      <button class="btn btn-sm"
                        :class="{ 'btn-primary': segment.logic === 'And', 'btn-outline-dark': segment.logic !== 'And' }"
                        @click="handleSetLogic(segment, 'And')">
                        AND
                      </button>
                      <button class="btn btn-sm ml-2"
                        :class="{ 'btn-primary': segment.logic === 'Or', 'btn-outline-dark': segment.logic !== 'Or' }"
                        @click="handleSetLogic(segment, 'Or')">
                        OR
                      </button>
                    </div>
                    <button v-if="segment.conditions" class="btn btn-sm btn-primary" @click="addCondition(segment)">
                      <i class="uil uil-plus"></i> Add Condition
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="w-100 mt-3">
            <button class="btn btn-primary pull-right" @click="addsegment">
              <i class="uil uil-plus"></i> Add segment
            </button>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="save">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import Vue from 'vue'
import uniqid from 'uniqid'
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone'

export default {
  components: {
    Multiselect
  },

  props: {
    block: {
      type: Object,
      default: null,
    },

    blocks: {
      type: Array,
      default: () => {
        return []
      },
    },

    automation: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      replyTo: [],
      isShowModal: false,
      waitContacts: null,
      operators: [
        { label: 'Is', value: 'Is' },
        { label: 'Is not', value: 'IsNot' },
        { label: 'Is after', value: 'IsAfter' },
        { label: 'Is before', value: 'IsBefore' },
      ],
      conditions: [
        {
          title: 'Date & Time',
          data: [
            { label: 'Current year', value: 'CurrentYear' },
            { label: 'Current day of the week', value: 'CurrentDayOfWeek' },
            { label: 'Current month', value: 'CurrentMonth' },
            { label: 'Current day of the month', value: 'CurrentDay' },
            { label: 'Current time', value: 'CurrentTime' },
          ]
        },
      ],
      timeStatements: [],
      dayStatements: [],
      monthStatements: [],
      yearStatements: [],
      dayOfWeekStatements: [],
    }
  },

  computed: {
    userTimezone() {
      let userTimezone = moment.tz.guess()
      let timezone = this.timezones.find(item => item.value === userTimezone)
      if (!timezone)
        timezone = this.timezones[0]
      return timezone
    },

    timezones() {
      return this.$store.getters['app/timezones']
    },

    durationOptions() {
      return new Array(60)
    },

    notifications() {
      let aBlocks = this.blocks.filter(item => item.level === this.block.level)
      let currentIndex = this.blocks.findIndex(item => item === this.block)
      aBlocks = aBlocks.slice(0, currentIndex)
      return aBlocks.filter(b => b.action === 'Notification' || b.action === 'SendEmail' && b.options.reply_tracking).map(b => {
        if (!b.id) {
          b.id = uniqid() + this.randomStr(6)
        }
        return b
      })
    },

    untilLabel() {
      let label = ''
      if (this.block.segments && this.block.segments.data)
      {
        this.block.segments.data.forEach((segment, i, arr) => {
          let conditions = segment.conditions
          conditions.forEach((condition, i1, arr1) => {
            label += `${condition.condition.label} ${condition.operator.label} ${condition.statement.label} (${condition.timezone.value}) `
            if (arr1.length - 1 !== i1)
              label += `<b>${segment.logic}</b> `
          })
          if (arr.length - 1 !== i)
            label += `<b>${this.block.segments.logic}</b> `
        });
      }
      return label
    }
  },

  mounted() {
    this.timeStatements = this.$store.getters['automation/conditionCurrentTimeValues']
    this.dayStatements = this.$store.getters['automation/conditionCurrentDayValues']
    this.monthStatements = this.$store.getters['automation/conditionCurrentMonthValues']
    this.yearStatements = this.$store.getters['automation/conditionCurrentYearValues']
    this.dayOfWeekStatements = this.$store.getters['automation/conditionCurrentDayOfWeekValues']

    if (this.block.options.timeout === undefined) {
      Vue.set(this.block.options, 'timeout', true)
    }

    if (this.block.options.key === undefined) {
      Vue.set(this.block.options, 'key', 'wait_for')
      Vue.set(this.block.options, 'label', 'Wait for')
    }

    if (this.block.options.reply_to) {
      this.replyTo = this.blocks.filter(item => this.block.options.reply_to.includes(item.id))
    }

    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.addsegment()
      this.showModal()
    }

    if (!this.block.options.action_type) {
      Vue.set(this.block.options, 'action_type', 1)
    }

    if (!this.block.segments) {
      Vue.set(this.block, 'segments', {
        logic: 'Or',
        data: []
      })
    }

    this.getWaitContacts()
  },

  methods: {
    onChangeCondition(item) {
      switch (item.condition.value) {
        case 'CurrentYear':
          item.statement = this.yearStatements[0]
          break;
        case 'CurrentMonth':
          item.statement = this.monthStatements[0]
          break;
        case 'CurrentDay':
          item.statement = this.dayStatements[0]
          break;
        case 'CurrentDayOfWeek':
          item.statement = this.dayOfWeekStatements[0]
          break;
        case 'CurrentTime':
        default:
          item.statement = this.timeStatements[0]
          break;
      }
    },

    getWaitContacts() {
      if (!this.block.id) return;

      this.$store
        .dispatch('automation/getWaitContacts', { automationId: this.automation.id, blockId: this.block.id })
        .then((totalContacts) => {
          this.waitContacts = totalContacts
        })
        .catch(() => {
        })
    },

    save() {
      this.block.options.reply_to = this.replyTo.map(item => item.id)
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },

    addsegment() {
      this.block.segments.data.push({
        logic: 'And',
        conditions: [
          {
            condition: { label: 'Current Time', value: 'CurrentTime' },
            timezone: this.userTimezone,
            operator: { label: 'Is', value: 'Is' },
            statement: this.timeStatements[0],
          }
        ]
      })
    },

    addCondition(segment) {
      segment.conditions.push({
        condition: { label: 'Current Time', value: 'CurrentTime' },
        timezone: this.userTimezone,
        operator: { label: 'Is', value: 'Is' },
        statement: this.timeStatements[0],
      })
    },

    handleSetLogic(segment, logic) {
      if (segment)
        segment.logic = logic
      else
        this.block.segments.logic = logic
    },

    handleRemoveSegment(index) {
      this.block.segments.data.splice(index, 1)
    },

    handleRemoveCondition(segmentIndex, conditionindex) {
      if (this.block.segments.data[segmentIndex]) {
        this.block.segments.data[segmentIndex].conditions.splice(conditionindex, 1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.segment {
  position: relative;
  background: #ebebeb;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.segment-delete {
  background: #ea4335;
  width: 28px;
  height: 28px;
  padding: 3px;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: -15px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
<style lang="scss">
.segment {
  .form-group {
    margin-bottom: 0!important;
  }
}
</style>