<template>
<div class="action-block">
  <div class="common-action blue" @click="showModal">
    <i :class="block.icon"></i> {{ block.name }}
  </div>

  <b-modal id="collect-contact-data-modal" v-model="isShowModal" title="Collect contact data"  size="md" >
    <TextInput v-model="block.name" label="Label" />
    <div class="form-group">
      <MergeFieldsGroup :show-keyword="true" :show-contact-link="true" :hide-title="true" @selected="appendField"/>
    </div>
    <h5 class="h5">Fields to collect</h5>
    <div v-for="(input, index) in block.collectFields" :key="`input-${index}`" class="form-group">
      <b-form-checkbox v-model="input.enable" :name="input.name + input.label">
        {{ input.label }}
      </b-form-checkbox>
      <template v-if="input.enable">
        <div class="d-flex">
          <textarea
            v-model="input.message"
            class="form-control"
            rows="2"
            style="resize:none;"
            @focus="selectEditor"
          ></textarea>
          <select v-if="['birthday', 'anniversary'].includes(input.name) || input.type === 2" v-model="input.format" class="form-control ml-2" style="width:140px;">
            <option selected value="mm/dd">mm/dd</option>
            <option value="dd/mm">dd/mm</option>
          </select>
        </div>
        <SMSLength :text="input.message" />
      </template>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import SMSLength from '../../../Common/SMSLength'

export default {
  components: {
    SMSLength,
  },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },


  data() {
    return {
      isShowModal: false,
      selectedEditor: null,
    }
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    appendField(field) {
      if (!this.selectedEditor) return
      this.selectedEditor.focus()
      document.execCommand('insertText', false, field)
    },
    
    selectEditor(event) {
      this.selectedEditor = event.target
    },

    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    }
  }
}
</script>

<style lang="scss">
#collect-contact-data-modal {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}

</style>