<template>
<div class="action-block">
  <div class="common-action dark-gray" @click="showModal">
    <i :class="block.icon"></i> {{ block.name }}
  </div>

  <b-modal id="notification-modal" v-model="isShowModal" title="Notification" size="lg">
    <TextInput v-model="block.name" label="Label" />

    <TextAreaAction v-model="block.options.content" label="Content"
      :rows="3" :show-mergefield="true" :sms-length="true" :hide-title="true" :show-redemption="false"
      :hide-expire-date="true" :hide-upload="true" :show-keyword="true" :show-contact-link="true" :hide-contact-dropdown="false" :hide-samcart-dropdown="false"
      :add-optout="true" name="message" rules="required"/>

    <!-- <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Method</label>
          <select v-model="block.options.method" class="form-control">
            <option value="Sms">SMS</option>
            <option value="Email">Email</option>
            <option value="EmailAndSms">Email + SMS</option>
          </select>
        </div>
      </div>
    </div> -->

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
export default {
  components: { },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
    }
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }

    this.block.options = {
      method: 'Sms',
      ...this.block.options
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
    },
  }
}
</script>

<style lang="scss" scoped>
</style>